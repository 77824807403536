"use client";

import UtmHandler from "@/components/atoms/UtmHandler";
import Footer from "@/components/layouts/Footer";
import Navbar from "@/components/layouts/Navbar";
import ScrollToTopArrow from "@/components/molecules/ScrollToTopArrow/ScrollToTopArrow";
import FloatingChat from "@/components/organisms/FloatingChat";
import { GET_SITE_SETTINGS } from "@/sanity/actions";
import { headers } from "next/headers";
import { usePathname } from "next/navigation";
import { Component, ReactNode, Suspense } from "react";



const Layout = async ({ children }: any) => {
  const siteData = await GET_SITE_SETTINGS();

  return (
    <div id="main-content" className={`scroll-smooth relative `}>
      <FloatingChat />
      <UtmHandler />
      <Suspense
        fallback={
          <Navbar
            categoryList={siteData.categoryList}
            linkList={siteData.navigation.navItemList}
          />
        }
      >
        <Navbar
          categoryList={siteData.categoryList}
          linkList={siteData.navigation.navItemList}
        />
      </Suspense>

      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
