"use client";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { urlForImage } from "@/sanity/lib/image";
import Image from "next/image";
import { useScrollLock } from "usehooks-ts";
type Props = {
  isMobileMenuOpen: boolean;
  linkList: any;
  setMobileMenu: any;
  categoryLinks: any;
};

const submenuVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const MobileMenu = ({
  isMobileMenuOpen,
  setMobileMenu,
  linkList,
  categoryLinks,
}: Props) => {
  const { lock, unlock } = useScrollLock({
    autoLock: false,
    lockTarget: "body",
  });
  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  let productList = {
    label: "Products",
    link: null,
    items: [
      categoryLinks.map((category: any) => {
        return {
          label: category.name,
          link: category.link,
        };
      }),
    ],
  };
  linkList = [productList, ...linkList];
  // useEffect(() => {
  //   console.log(activeMenu);
  // }, [activeMenu]);
  const toggleSubmenu = (title: string) => {
    if (activeMenu === title) {
      setActiveMenu(null); // Close submenu if it's already open
    } else {
      setActiveMenu(title);
    }
  };
  useEffect(() => {
    if (isMobileMenuOpen) {
      lock();
      document.body.style.paddingRight = "0";
    } else {
      unlock();
    }
  }, [isMobileMenuOpen]);
  return (
    <div className="block relative nav-breakpoint:hidden">
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            className="fixed top-[111px] left-0 w-full h-full bg-main-dark-green container z-50"
            initial="closed"
            animate="open"
            exit="closed"
            variants={{
              open: { opacity: 1 },
              closed: { opacity: 0 },
            }}
            transition={{ type: "spring", damping: 30, stiffness: 300 }}
          >
            <div className="absolute bottom-0 left-0 pointer-events-none">
              <Image
                src={"/mobileOverlay.png"}
                width={300}
                height={300}
                alt={"overlay"}
              />
            </div>
            {activeMenu && (
              <motion.div
                className="cursor-pointer flex items-center gap-4 relative mt-8 text-[26px] font-semibold text-white"
                onClick={() => setActiveMenu(null)}
              >
                <FaArrowLeft className="text-white/50" size={24} /> {activeMenu}
              </motion.div>
            )}
            <div className="mt-8">
              <AnimatePresence mode="popLayout">
                {!activeMenu &&
                  linkList.map((link: any) => {
                    if (link.items) {
                      return (
                        <motion.div
                          key={link.label}
                          onClick={() => toggleSubmenu(link.label)}
                          initial="closed"
                          animate="open"
                          variants={{
                            open: { opacity: 1 },
                            closed: { opacity: 0 },
                          }}
                          className="flex justify-between text-[26px] text-white cursor-pointer pointer-cursor items-center py-2"
                        >
                          {link.label}

                          <FaArrowRight className="text-white/50 text-3xl cursor-pointer" />
                        </motion.div>
                      );
                    }
                    return (
                      <Link
                        href={link.link || "/"}
                        onClick={() => setMobileMenu(false)}
                        className="text-[26px] text-white cursor-pointer"
                      >
                        <motion.div
                          key={link.label}
                          initial="closed"
                          animate="open"
                          variants={{
                            open: { opacity: 1 },
                            closed: { opacity: 0 },
                          }}
                          className="flex justify-between items-center py-2"
                        >
                          {link.label}
                        </motion.div>
                      </Link>
                    );
                  })}
                {activeMenu === "Why Enviroplus?" && (
                  <div>
                    <AnimatePresence mode="popLayout">
                      {linkList
                        .find((link: any) => link.label === "Why Enviroplus?")
                        ?.items.map((page: any, key: number) => {
                          return (
                            <Link
                              key={key}
                              onClick={() => {
                                setActiveMenu(null);
                                setMobileMenu(false);
                              }}
                              href={page.link}
                            >
                              <motion.div
                                key={key}
                                initial="closed"
                                animate="open"
                                variants={{
                                  open: { opacity: 1 },
                                  closed: { opacity: 0 },
                                }}
                                className="relative py-8 px-4 flex justify-between items-center mb-4 rounded-[8px] text-white text-[20px] font-semibold rounded-bl-none overflow-hidden"
                              >
                                <style jsx>{`
                                  div:before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background: url(${urlForImage(
                                        page.imageOverlay
                                      ).url()})
                                      no-repeat center center;
                                    background-size: cover;
                                    filter: brightness(0.8);
                                    z-index: -1;
                                  }
                                `}</style>
                                <p>{page.label}</p>
                                <div className="w-9 h-9 rounded-[4px] flex items-center justify-center bg-main-accent">
                                  <FaArrowRight
                                    size={24}
                                    className="text-main-dark-green"
                                  />
                                </div>
                              </motion.div>
                            </Link>
                          );
                        })}
                    </AnimatePresence>
                  </div>
                )}
                {activeMenu === "Products" && (
                  <div>
                    <p className="text-[10px] text-white/35 font-semibold py-4">
                      BROWSE BY
                    </p>
                    <div className="flex flex-col space-y-4">
                      <AnimatePresence mode="popLayout">
                        {categoryLinks.map((category: any, key: number) => {
                          return (
                            <Link
                              key={key}
                              onClick={() => {
                                setActiveMenu(null);
                                setMobileMenu(false);
                              }}
                              href={category.link}
                            >
                              <motion.div
                                initial="closed"
                                animate="open"
                                className="text-white text-[18px] font-semibold cursor-pointer"
                                variants={{
                                  open: { opacity: 1 },
                                  closed: { opacity: 0 },
                                }}
                              >
                                {category.name}
                              </motion.div>
                            </Link>
                          );
                        })}
                        <Link
                          href={"/products"}
                          onClick={() => {
                            setMobileMenu(false);
                            setActiveMenu(null);
                          }}
                          className="flex border-t border-t-white/30 pt-4 items-center justify-between "
                        >
                          <h3 className="text-[18px] text-white font-semibold">
                            All products
                          </h3>
                          <div className="w-9 h-9 rounded-[4px] flex items-center justify-center bg-main-accent">
                            <FaArrowRight
                              size={24}
                              className="text-main-dark-green"
                            />
                          </div>
                        </Link>
                      </AnimatePresence>
                    </div>
                  </div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MobileMenu;
